<template>
	<div class="container">
		<div>
            <div class="mt-5 mb-3">
                <label for="formFile" class="form-label">Wybierz plik</label>
                <input class="form-control" type="file" id="formFile"  v-on:change="handleFileUpload( $event )"  :disabled="isSubmited && !isDone">
            </div>
            <div v-if="isSubmited && !isDone">
                <p class="text-end mb-0">
                    {{ uploadPercentage }}%
                </p>
                <div class="progress">
                    <div class="progress-bar progress-bar-striped progress-bar-animated"
                         role="progressbar"
                         :aria-valuenow="uploadPercentage"
                         aria-valuemin="0"
                         aria-valuemax="100"
                         :style="'width:' + uploadPercentage + '%'"
                    ></div>
                </div>
            </div>

            <div v-if="isDone">
                <div class="alert alert-success" role="alert" v-if="isSuccess">
                    Plik został wgrany poprawnie! Oto adres kierujący do wgranego pliku<br/>
                    <a :href="fileUrl" class="alert-link">{{ fileUrl }}</a>
                </div>
                <div class="alert alert-danger" role="alert" v-if="isFail">
                    {{ failMessage }}
                </div>
            </div>
			<button class="btn btn-primary mt-4" v-on:click="submitFile()" :disabled="isSubmited && !isDone">Wgraj</button>
		</div>
	</div>
</template>

<script>
	import axios from 'axios';
	
	export default {
		data(){
			return {
				file: '',
				uploadPercentage: 0,
                isSubmited: false,
                isDone: false,
                isSuccess: false,
                isFail: false,
                failMessage: '',
                fileUrl: '',
			}
		},
		
		methods: {
			handleFileUpload( event ){
				this.file = event.target.files[0];

                if (this.failMessage.length) {
                    this.isDone = false;
                    this.isFail = false;
                    this.failMessage = ''
                }
			},
			
			submitFile(){
                if (!this.file) {
                    this.isDone = true;
                    this.isFail = true;
                    this.failMessage = 'Wybierz plik który chcesz wgrać.'
                    return;
                }

                this.uploadPercentage = 0;
                this.isSubmited = true
                this.isDone = false
                this.isSuccess = false
                this.isFail = false
                this.failMessage = ''
                this.fileUrl = ''

				/*
					Initialize the form data
				*/
				let formData = new FormData();
			
				/*
					Add the form data we need to submit
				*/
				formData.append('file', this.file);
			
				/*
					Make the request to the POST /single-file URL
				*/
				axios.post( '/upload.php',
					formData,
					{
						headers: {
								'Content-Type': 'multipart/form-data'
						},
						onUploadProgress: function( progressEvent ) {
							this.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ) );
						}.bind(this)
					}
				).then(data => {
                    const response = data.data;
                    this.isDone = true;
                    this.isSubmited = false;
                    console.log(data)

                    if(response.success) {
                        this.isSuccess = true;
                        this.fileUrl = response.fileUrl;
                        this.file = '';
                    } else {
                        this.isSuccess = false;
                        this.isFail = true;
                        this.failMessage = response.message;
                    }
				})
				.catch(data => {
                    const response = data.data;
                    this.isDone = true;
                    this.isSubmited = false;

                    if(!response.success) {
                        this.isSuccess = false;
                        this.isFail = true;
                        this.failMessage = response.message;
                    }
				});
			},
		}
	}
</script>